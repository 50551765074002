<template>
  <validation-observer ref="percentageForm" #default="{ invalid }">
    <b-form @submit="onSubmit">

      <!-- Valor Base -->
      <b-form-group
        v-if="typeValuePercentage == 'Valor Base'"
        label="Valor Base"
        label-for="base"
        description="Valor Base."
      >
        <b-form-input v-model="form.baseValue" type="number" min="0"></b-form-input>
      </b-form-group>

      <!-- Valor Maximo Declarado -->
      <b-form-group
        v-if="typeValuePercentage == 'Valor Maximo Declarado'"
        label="Valor Maximo Declarado"
        label-for="max"
      >
        <b-form-input
          id="max"
          v-model="form.maxValue"
          type="number"
          min="0"
        ></b-form-input>
      </b-form-group>

      <!-- Valor Declarado -->
      <b-form-group
        v-if="typeValuePercentage == 'Valor Declarado'"
        label="Valor declarado"
        label-for="percentage"
        description="Porcentaje aplicado al valor declarado."
      >
        <b-form-input
          v-model="form.percentageDeclared"
          id="percentage"
          type="number"
          max="100"
          min="0"
          step="0.01"
          placeholder="Ingresar valores dentro de 0 y 100 %"
        ></b-form-input>
      </b-form-group>

      <!-- Condiciones de Contratación -->
      <b-form-group
        label="Condiciones de Contratación"
        label-for="contract"
        v-if="typeValuePercentage == 'Contrato'"
      >
        <b-form-file
          v-model="form.contract_pdf"
          @input="onContract"
          accept="application/pdf"
        ></b-form-file>
      </b-form-group>

      <b-button type="submit" :disabled="invalid" variant="primary" class="mt-4">
        Guardar
      </b-button>
      <b-button class="mt-4 ml-3" variant="outline-primary" @click="$emit('close')">
        Cerrar
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver } from "vee-validate";
import { BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import SettingService from "@/services/SettingService";

export default {
  name: "PercentageDeclaredForm",
  components: {
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    form: Object,
    typeValuePercentage: String,
    onSave: Object,
  },
  methods: {
    /**
     *
     */
    async onContract() {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.contract = reader.result;
      };
      reader.readAsDataURL(this.form.contract_pdf);
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.percentageForm.validate().then((valid) => {
        if (valid) {
          this.isLoading = true;
          SettingService.setDeclaredPercentage(this.form)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Configuración`,
                  text: `Operacion exitosa`,
                  icon: "InfoIcon",
                  variant: "info",
                },
              });
              this.$emit("close");
              this.onSave.handler();
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Configuración`,
                  text: `Error al tratar de almacenar`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>
