<template>
  <b-row>
    <b-col>
      <b-card class="py-2 px-2 m-1">
        <b-card-body>
          <loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
          ></loading>
          <b-row>
            <b-col sm="12" md="12" class="d-flex justify-content-between">
              <div class="h4">
                <p>Valor Base</p>
              </div>
              <div>
                <feather-icon
                  @click="onEdit(form, 'Valor Base')"
                  size="25"
                  icon="Edit3Icon"
                  style="color: #f68d1e; cursor: pointer"
                  class="mr-50"
                />
              </div>
            </b-col>
            <b-col
              sm="12"
              md="12"
              class="d-flex justify-content-center mt-3 mb-2"
            >
              <div>
                <p style="font-size: 60px; font-weight: 900; color: #f68d1e">
                  {{ form.baseValue }}$
                </p>
              </div>
            </b-col>
            <b-col sm="12" md="12" class="d-flex justify-content-center">
              <div class="d-block">
                <p>Valor Base</p>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="py-2 px-2 m-1">
        <b-card-body>
          <loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
          ></loading>
          <b-row>
            <b-col sm="12" md="12" class="d-flex justify-content-between">
              <div class="h4">
                <p>Valor Declarado</p>
              </div>
              <div>
                <feather-icon
                  @click="onEdit(form, 'Valor Declarado')"
                  size="25"
                  icon="Edit3Icon"
                  style="color: #f68d1e; cursor: pointer"
                  class="mr-50"
                />
              </div>
            </b-col>
            <b-col
              sm="12"
              md="12"
              class="d-flex justify-content-center mt-3 mb-2"
            >
              <div>
                <p style="font-size: 60px; font-weight: 900; color: #f68d1e">
                  {{ form.percentageDeclared }}%
                </p>
              </div>
            </b-col>
            <b-col sm="12" md="12" class="d-flex justify-content-center">
              <div class="d-block">
                <p>Porcentaje aplicado al valor agregado</p>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col>
      <b-card class="py-2 px-2 m-1">
        <b-card-body>
          <loading
            :active="isLoading"
            :can-cancel="false"
            :is-full-page="false"
          ></loading>
          <b-row>
            <b-col sm="12" md="12" class="d-flex justify-content-between">
              <div class="h4">
                <p>Valor Máximo Declarado</p>
              </div>
              <div>
                <feather-icon
                  @click="onEdit(form, 'Valor Maximo Declarado')"
                  size="25"
                  icon="Edit3Icon"
                  style="color: #f68d1e; cursor: pointer"
                  class="mr-50"
                />
              </div>
            </b-col>
            <b-col
              sm="12"
              md="12"
              class="d-flex justify-content-center mt-3 mb-2"
            >
              <div>
                <p style="font-size: 60px; font-weight: 900; color: #f68d1e">
                  {{ form.maxValue }}$
                </p>
              </div>
            </b-col>
            <b-col sm="12" md="12" class="d-flex justify-content-center">
              <div class="d-block">
                <p>Valor Máximo Declarado</p>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card class="py-2 px-2 m-1">
        <b-card-body>
          <b-col sm="12" md="12" class="d-flex justify-content-between">
            <p class="h4">Contrato</p>
            <div>
              <feather-icon
                @click="onEdit(form, 'Contrato')"
                size="25"
                icon="Edit3Icon"
                style="color: #f68d1e; cursor: pointer"
                class="mr-50"
              />
            </div>
          </b-col>
          <b-col
            sm="12"
            md="12"
            class="d-flex justify-content-center"
            v-if="form.pdf"
          >
            <a class="btn btn-primary" :href="form.pdf" target="blank" v-if="form.pdf">Documento</a>
          </b-col>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PercentageDeclaredForm from "./PercentageDeclaredForm";
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import SettingService from "@/services/SettingService";

export default {
  name: "SettingPercentageDeclared",
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      isLoading: false,
      form: {
        baseValue: 0,
        maxValue: 0,
        percentageDeclared: null,
        contract_pdf: null,
        contract: null,
      },
    };
  },
  methods: {
    onEdit(form, typeValuePercentage) {
      this.$modal.show(
        PercentageDeclaredForm,
        {
          form: Object.assign({}, form),
          typeValuePercentage,
          onSave: {
            handler: () => {
              this.show();
            },
          },
        },
        {
          styles:
            "max-height: 500px; border-radius: 10px; padding: 50px; margin-left: 10%; margin-top: -8%",
          adaptive: true,
          width: "35%",
        }
      );
    },

    show() {
      this.isLoading = true;
      SettingService.declaredPercentage()
        .then(({ data }) => {
          this.isLoading = false;
          this.form = data;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Configuración`,
              text: `Error al tratar de cargar información`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  mounted() {
    this.show();
  },
};
</script>
